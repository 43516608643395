import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { ProjectSettings } from "config";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    debug: false,
    detection: {
      order: ["queryString", "cookie", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: Object.keys(ProjectSettings.other().LANGUAGES),
    fallbackLng: () =>
      navigator.language === "bs" || navigator.language === "hr"
        ? "sr"
        : Object.keys(ProjectSettings.other().LANGUAGES).find(
            (lang) => ProjectSettings.other().LANGUAGES[lang].DEFAULT
          ) || "en",
  })
  .then((r) => {
    // console.log("initialize language success", r);
  });

export default i18n;
